import { $api, configGet } from "../constant";
import { HOST_API_V1 } from "../env-init";

const BASE_URL = HOST_API_V1 + "auth/";
const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const login = async (payload: any) => {
  const res = await $api().post(BASE_URL + "login", payload, config);
  return res.data;
};

export const getMenu = async () => {
  const res = await $api().get(BASE_URL + "menu", configGet());
  return res.data;
};
